//
// taskboard.scss
//

.tasklist {
    min-height: 40px;
    margin-bottom: 0;

    > li {
        background-color: $card-bg;
        border: $border-width solid $border-color;
        padding: 20px;
        margin-bottom: 15px;
        border-radius: 3px;
    }
    .kanban-detail {
        margin-inline-start: 35px;
        li {
            i {
                font-size: 16px;
                color: #{map-get($grays, "600")};
                padding: 0px 5px;
                line-height: 32px;
            }
        }
    }
}

body[data-layout-color="dark"] {
    .kanban-detail {
        li {
            i {
                color: #{map-get($dark-grays, "600")};
            }
        }
    }
}
