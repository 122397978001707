.message-list::-webkit-scrollbar {
    width: 0.4rem;
}

.message-list::-webkit-scrollbar-track {
    background: transparent;
}

.message-list::-webkit-scrollbar-thumb {
    background: #c3c4c7;
    border-radius: 2rem;
    border-width: 0;
}

.message-list::-webkit-scrollbar-thumb:hover {
    background: #c3c4c7;
    border-radius: 2rem;
    border-style: solid;
    border-width: 0;
}
