// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-inline-start: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-inline-start: 8px;
    }
}

// Grid
.grid-structure {
    .grid-container {
        background-color: $table-striped-bg;
        margin-bottom: 10px;
        font-size: 0.8rem;
        font-weight: $font-weight-semibold;
        padding: 10px 20px;
    }
}

// Icon demo ( Demo only )
.icons-list-demo {
    div.col-sm-6 {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }
    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-inline-end: 12px;
        color: $text-muted;
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }
    .col-md-4 {
        border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;
        &:hover,
        &:hover i {
            color: $primary;
        }
    }

    .icon-item{
        svg{
            margin-inline-end: 10px;
        }
        
        span{
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}


// Scrollspy

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

// list group

.list-group-item {
    border: #{$border-width} solid #{lighten(map-get($grays, "200"), 2%)};
    border-left: none;
    border-right: none;
    &:hover{
        background: #{lighten(map-get($grays, "200"), 2%)};
    }
    &:first-child{
        border-top: none !important;
    }
    &:last-child{
        border-bottom: none !important;
    }
}


// dark mode
body[data-layout-color="dark"] {
    .list-group-item {
        border: #{$border-width} solid #{lighten(map-get($dark-grays, "200"), 2%)};
        &:hover{
            background: #{lighten(map-get($dark-grays, "200"), 2%)};
        }
    }
}