// 
// type.scss
//
.ltr{
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 10px 0;
        font-family: $font-family-secondary;
        color: #{map-get($grays, "800")};
        font-weight: $font-weight-semibold;
    }
}
.rtl{
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 10px 0;
        font-family: $font-family-arabic;
        color: #{map-get($grays, "800")};
        font-weight: $font-weight-semibold;
    }
}


// Font sizes (Custom)
.font-11 {
    font-size: 11px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-13 {
    font-size: 13px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-17 {
    font-size: 17px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-19 {
    font-size: 19px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-26 {
    font-size: 26px !important;
}

.font-28 {
    font-size: 28px !important;
}

// Font Weight

.fw-medium {
    font-weight: $font-weight-medium;
}

.fw-semibold {
    font-weight: $font-weight-semibold;
}

// dark mode
body[data-layout-color="dark"] {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #{map-get($dark-grays, "800")};
    }
}