// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  &.rtl{
    font-family: $font-family-arabic!important;
  }
  &.ltr{
    font-family: $font-family-base!important;
  }
}

