//
// right-sidebar.scss
//

.rtl{
    .right-bar {
        background-color: $rightbar-bg;
        box-shadow: 0 0 24px 0 rgba($black, 0.06), 0 1px 0 0 rgba($black, 0.02);
        display: block;
        position: fixed;
        transition: all 200ms ease-out;
        width: $rightbar-width;
        z-index: 9999;
        float: left;
        left: -($rightbar-width + 10px);
        top: 0;
        bottom: 0;

        .rightbar-title {
            background-color: $primary;
            padding: 25.5px 25px;
            color: $white;
        }
        .right-bar-toggle {
            background-color: #{map-get($grays, "800")};
            height: 24px;
            width: 24px;
            line-height: 24px;
            color: #{map-get($grays, "200")};
            text-align: center;
            border-radius: 50%;
            margin-top: -4px;

            &:hover {
                background-color: #{lighten(map-get($grays, "900"), 10%)};
            }
        }
        .user-box {
            padding: 25px;
            text-align: center;
            .user-img {
                position: relative;
                height: 64px;
                width: 64px;
                margin: 0 auto 15px auto;
                .user-edit {
                    position: absolute;
                    right: -5px;
                    bottom: 0px;
                    height: 24px;
                    width: 24px;
                    background-color: $white;
                    line-height: 24px;
                    border-radius: 50%;
                    box-shadow: $components-shadow-lg;
                }
            }
            h5 {
                margin-bottom: 2px;
                a {
                    color: #{map-get($grays, "900")};
                }
            }
        }
    }



    // Rightbar overlay
    .rightbar-overlay {
        background-color: #{rgba(map-get($grays, "900"), 0.55)};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 9998;
        transition: all 0.2s ease-out;
    }

    &.right-bar-enabled {
        .right-bar {
            left: 0;
        }
        .rightbar-overlay {
            display: block;
        }
    }
}

.ltr{
    .right-bar {
        background-color: $rightbar-bg;
        box-shadow: 0 0 24px 0 rgba($black, 0.06), 0 1px 0 0 rgba($black, 0.02);
        display: block;
        position: fixed;
        transition: all 200ms ease-out;
        width: $rightbar-width;
        z-index: 9999;
        float: right;
        right: -($rightbar-width + 10px);
        top: 0;
        bottom: 0;

        .rightbar-title {
            background-color: $primary;
            padding: 25.5px 25px;
            color: $white;
        }
        .right-bar-toggle {
            background-color: #{map-get($grays, "800")};
            height: 24px;
            width: 24px;
            line-height: 24px;
            color: #{map-get($grays, "200")};
            text-align: center;
            border-radius: 50%;
            margin-top: -4px;

            &:hover {
                background-color: #{lighten(map-get($grays, "900"), 10%)};
            }
        }
        .user-box {
            padding: 25px;
            text-align: center;
            .user-img {
                position: relative;
                height: 64px;
                width: 64px;
                margin: 0 auto 15px auto;
                .user-edit {
                    position: absolute;
                    right: -5px;
                    bottom: 0px;
                    height: 24px;
                    width: 24px;
                    background-color: $white;
                    line-height: 24px;
                    border-radius: 50%;
                    box-shadow: $components-shadow-lg;
                }
            }
            h5 {
                margin-bottom: 2px;
                a {
                    color: #{map-get($grays, "900")};
                }
            }
        }
    }



    // Rightbar overlay
    .rightbar-overlay {
        background-color: #{rgba(map-get($grays, "900"), 0.55)};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 9998;
        transition: all 0.2s ease-out;
    }

    &.right-bar-enabled {
        .right-bar {
            right: 0;
        }
        .rightbar-overlay {
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}

// dark mode
body[data-layout-color="dark"] {
    .right-bar {
        .right-bar-toggle {
            color: #{map-get($dark-grays, "800")};
            &:hover {
                background-color: #{lighten(map-get($dark-grays, "200"), 10%)};
            }
        }
        .user-box {
            h5 {
                a {
                    color: #{map-get($dark-grays, "800")};
                }
            }
        }
    }
    .rightbar-overlay {
        background-color: #{rgba(map-get($dark-grays, "800"), 0.55)};
    }
}
